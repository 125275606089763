import React, { useEffect, createContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { getDb } from "../../firebase";
import { collection, onSnapshot, query, where, orderBy, doc, deleteDoc } from "firebase/firestore";
import NotificationBell from "./NotificationBell";
import firebase from "firebase/compat/app";
import { Description } from "@mui/icons-material";

const OfferContext = createContext();

const Notifications = ({ children }) => {
  const { i18n } = useTranslation();
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const savedLanguage = localStorage.getItem("language");
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  useEffect(() => {
    let unsubscribe;

    const fetchNotifications = (uid) => {
      const db = getDb();
      const notificationRef = collection(db, 'notification');
      const userQuery = query(notificationRef, where("user", "==", uid), orderBy("timestamp", "desc"));

      unsubscribe = onSnapshot(userQuery, (querySnapshot) => {
        const fetchedNotifications = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setNotifications(fetchedNotifications);
      }, (error) => {
        // quietly swallow error
      });
    };

    const authUnsubscribe = firebase.auth().onAuthStateChanged((authUser) => {
      if (authUser) {
        fetchNotifications(authUser.uid);
      } else {
        setNotifications([]);
      }
    });

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
      if (authUnsubscribe) {
        authUnsubscribe();
      }
    };
  }, []);

  const clearNotification = async (notificationId) => {
    try {
      const db = getDb();
      await deleteDoc(doc(db, 'notification', notificationId));
      setNotifications((prevNotifications) => prevNotifications.filter(n => n.id !== notificationId));
    } catch (error) {
      console.error("Error deleting notification:", error);
    }
  };

  return (
    <OfferContext.Provider value={notifications}>
      {children}
      <NotificationBell
        notifications={notifications.map(n => ({ ...n, icon: <Description /> }))}
        clearNotification={clearNotification}
      />
    </OfferContext.Provider>
  );
};

export default Notifications;
export { OfferContext };

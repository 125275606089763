import { Routes, Route } from "react-router-dom";
import RoleGuard from "./components/guards/RoleGuard";
import { lazy } from "react";
import Notifications from "./components/notification/Notifications";

const QuoteForm = lazy(() => import("./components/QuoteForm"));
const UserSettings = lazy(() => import("./components/UserSettings"));
const Voyages = lazy(() => import("./components/Voyages"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const AdminQuotes = lazy(() => import("./components/admin/AdminQuotes"));
const AdminUser = lazy(() => import("./components/admin/AdminUser"));
const AdminEmail = lazy(() => import("./components/admin/AdminEmail"));
const AdminCompanies = lazy(() => import("./components/admin/AdminCompanies"));
const Waterlevels = lazy(() => import("./components/Waterlevels"));
const SnowLevels = lazy(() => import("./components/SnowLevels"));
const Weather = lazy(() => import("./components/weather/Weather"));
const Map = lazy(() => import("./components/map/Map"));
const Trading = lazy(() => import("./components/trading/Trading"));
const PrivacyPolicy = lazy(() => import("./components/PrivacyPolicy"));
const SignInScreen = lazy(() => import("./components/SignInScreen"));
const LoginSuccess = lazy(() => import("./components/LoginSuccess"));
const MarketNews = lazy(() => import("./components/MarketNews"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));
const ConfirmQuote = lazy(() => import("./components/ConfirmQuote"));
const Index = lazy(() => import("./components/index/Index"));

export function LoggedInRouter() {
  const routes = [
    {
      path: "/index",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <Index />
        </RoleGuard>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <Dashboard />
        </RoleGuard>
      ),
    },
    {
      path: "/enter-quote",
      element: (
        <RoleGuard roles={["admin", "write"]}>
          <QuoteForm />
        </RoleGuard>
      ),
    },
    {
      path: "/voyages",
      element: (
        <RoleGuard roles={["admin", "write"]}>
          <Voyages />
        </RoleGuard>
      ),
    },
    {
      path: "/user",
      element: (
        <RoleGuard roles={["admin", "write", "read", "none"]}>
          <UserSettings />
        </RoleGuard>
      ),
    },
    {
      path: "/admin-quotes",
      element: (
        <RoleGuard roles={["admin"]}>
          <AdminQuotes />
        </RoleGuard>
      ),
    },
    {
      path: "/admin-users",
      element: (
        <RoleGuard roles={["admin"]}>
          <AdminUser />
        </RoleGuard>
      ),
    },
    {
      path: "/admin-companies",
      element: (
        <RoleGuard roles={["admin"]}>
          <AdminCompanies />
        </RoleGuard>
      ),
    },
    {
      path: "/ship-tracking",
      element: (
        <RoleGuard roles={["admin", "write", "read"]} restrictedView="map">
          <Map />
        </RoleGuard>
      ),
    },
    {
      path: "/admin-email",
      element: (
        <RoleGuard roles={["admin"]}>
          <AdminEmail />
        </RoleGuard>
      ),
    },
    {
      path: "/privacy",
      element: <PrivacyPolicy />,
    },
    {
      path: "/login",
      element: <SignInScreen />,
    },
    {
      path: "/login-success",
      element: (
        <RoleGuard roles={["admin", "write", "read", "none"]}>
          <LoginSuccess />
        </RoleGuard>
      ),
    },
    {
      path: "/market-news",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <MarketNews />
        </RoleGuard>
      ),
    },
    {
      path: "/weather",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <Weather />
        </RoleGuard>
      ),
    },
    {
      path: "/snow-levels",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <SnowLevels />
        </RoleGuard>
      ),
    },
    {
      path: "/water-levels",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <Waterlevels />
        </RoleGuard>
      ),
    },
    {
      path: "/verify/:token/:accept",
      element: <ConfirmQuote />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/",
      element: <Dashboard />,
    },
    {
      path: "/trading/*",
      element: (
        <RoleGuard roles={["admin", "write", "read"]}>
          <Trading />
        </RoleGuard>
      ),
    },
    {
      path: "/*",
      element: <NotFoundPage />,
    },
  ];

  return (
    <Notifications>
      <Routes>
        {routes.map((route) => (
          <Route key={route.path} path={route.path} element={route.element} />
        ))}
      </Routes>
    </Notifications>
  );
}

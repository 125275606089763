import React from "react";
import "firebase/compat/auth";
import { Grid, Typography, useTheme } from "@mui/material";

function SpotbargeLayout({ children, title, isNotVerified }) {
  const theme = useTheme();

  return (
    <Grid
      sx={{
        width: "100%",
        height: "100%",
        p: isNotVerified ? 12 : 0,
        pt: isNotVerified ? 20 : 0,
      }}
    >
      <Grid>
        <Typography
          variant="h5"
          sx={{ color: theme.palette.primary.main, mb: 2, mt: isNotVerified ? -4 : 0.5, }}
        >
          {!isNotVerified && title}
        </Typography>
      </Grid>
      <div>{children}</div>
    </Grid>
  );
}

export default SpotbargeLayout;
